import { firebaseAnalytics } from 'app/shared/analytics';
import { useNotificationSettingsEntityApi } from 'app/shared/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IDeleteUserNotificationAssignmentsPayload,
  IGetUserNotificationAssignmentsPayload,
  INotification,
  ITeamAssignments,
  NotificationEntityType,
  Routes,
} from 'types';
import { NotificationSettings as NotificationSettingsComponent } from 'app/shared/components/generic-ui';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { useSearchParams } from '../hooks';
import { PaginationType } from 'app/shared/components/generic-ui/Table/Table.types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useNotificationSettings } from 'app/shared/providers';
import { useUserNotificationAssignmentApi } from 'app/shared/hooks/useUserNotificationAssignmentApi';
import { UserAssignmentModal } from 'app/shared/components/generic-ui/UserAssignmentModal/UserAssignmentModal';

const skipOnePage = 1;

interface IEntityAssignmentsProps {
  isUnAssigningUser: boolean;
}

export const EntityAssignments = ({
  isUnAssigningUser,
}: IEntityAssignmentsProps) => {
  const { t } = useTranslation();

  const { sortHandler, searchParams, sortType, searchQuery } =
    useSearchParams();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [notification, setNotification] = useState<INotification>();
  const [teamAssignment, setTeamAssignment] = useState<ITeamAssignments>();

  const { selectedEntity, entityType } = useNotificationSettings();

  const {
    organizationId,
    page,
    perPage,
    tab,
  }: { organizationId: string; page: string; perPage: string; tab: string } =
    useParams();

  const [search, setSearch] = useState('');

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        skip: (+page - skipOnePage) * +perPage,
        take: perPage,
        query: searchQuery || '',
        sortBy: 'firstname,lastname',
        sortAsc: sortType === 'desc' ? 'false' : 'true',
        notificationEntityId:
          entityType === NotificationEntityType.ORGANIZATION
            ? organizationId
            : selectedEntity?.internalId || '',
      },
    };
  }, [
    entityType,
    organizationId,
    page,
    perPage,
    searchQuery,
    selectedEntity?.internalId,
    sortType,
  ]);

  const searchQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        query: search,
        skip: 0,
        take: 10,
        sortBy: 'firstname,lastname',
        sortAsc: true,
        notificationEntityId:
          entityType === NotificationEntityType.ORGANIZATION
            ? organizationId
            : selectedEntity?.internalId || '',
      },
    };
  }, [entityType, organizationId, search, selectedEntity?.internalId]);

  const notificationsQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        notificationEntityId:
          entityType === NotificationEntityType.ORGANIZATION
            ? organizationId
            : selectedEntity?.internalId || '',
      },
    };
  }, [entityType, organizationId, selectedEntity?.internalId]);

  const {
    searchNotificationSettingsAssignments,
    fetchSearchTeamAssignmentsEntity,
    isFetchingSearchTeamAssignmentsEntity,
  } = useNotificationSettingsEntityApi(searchQueryParams);

  const {
    fetchNotificationSettingsEntity,
    isFetchingNotificationSettingsEntity,
    notificationSettingsEntity,
    changeNotificationStatusEntity,
    changeNotificationStatusAllGroup,
    enableNotificationEntity,
    enableNotificationAllGroup,
    changeNotificationPropertyEntity,
    changeUsersStatusNotificationEntity,
  } = useNotificationSettingsEntityApi(notificationsQueryParams);

  const {
    deleteUserNotificationAssignments,
    getUserNotificationAssignments,
    getUserNotificationAssignmentsResponse,
    isLoadingUserNotificationAssignments,
  } = useUserNotificationAssignmentApi({
    organizationId,
    notificationEntityId:
      entityType === NotificationEntityType.ORGANIZATION
        ? organizationId
        : selectedEntity?.internalId || '',
  });

  const {
    fetchNotificationSettingsAssignments,
    isFetchingNotificationSettingsAssignments,
    notificationSettingsAssignments,
    notificationSettingsAssignmentsMetadata,
  } = useNotificationSettingsEntityApi(queryParams);

  useEffect(() => {
    if (
      selectedEntity?.internalId ||
      entityType === NotificationEntityType.ORGANIZATION
    ) {
      fetchNotificationSettingsEntity();
    }
  }, [entityType, fetchNotificationSettingsEntity, selectedEntity]);

  useEffect(() => {
    if (
      (selectedEntity?.internalId ||
        entityType === NotificationEntityType.ORGANIZATION) &&
      !isUnAssigningUser
    ) {
      fetchNotificationSettingsAssignments();
    }
  }, [
    entityType,
    fetchNotificationSettingsAssignments,
    selectedEntity,
    queryParams,
    isUnAssigningUser,
  ]);

  useEffect(() => {
    if (
      search &&
      (selectedEntity?.internalId ||
        entityType === NotificationEntityType.ORGANIZATION)
    ) {
      fetchSearchTeamAssignmentsEntity();
    }
  }, [entityType, fetchSearchTeamAssignmentsEntity, search, selectedEntity]);

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  const removeAllOtherUserNotificationAssignments = useCallback(
    async (params: IDeleteUserNotificationAssignmentsPayload) => {
      await deleteUserNotificationAssignments(params);
      if (
        selectedEntity?.internalId ||
        entityType === NotificationEntityType.ORGANIZATION
      ) {
        if (!isUnAssigningUser) {
          fetchNotificationSettingsAssignments();
        }
        fetchNotificationSettingsEntity();
      }
    },
    [
      deleteUserNotificationAssignments,
      entityType,
      fetchNotificationSettingsAssignments,
      fetchNotificationSettingsEntity,
      isUnAssigningUser,
      selectedEntity?.internalId,
    ],
  );

  const getUserNotificationAssignmentsCall = useCallback(
    async (
      params: IGetUserNotificationAssignmentsPayload,
      notification: INotification | undefined,
      teamAssignment: ITeamAssignments,
    ) => {
      getUserNotificationAssignments(params);
      setUserModalOpen(true);
      setNotification(notification);
      setTeamAssignment(teamAssignment);
    },
    [getUserNotificationAssignments],
  );

  const changeNotificationStatusHandler = useCallback(
    async (notification: { notificationTypeId?: string; active: boolean }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `${entityName} notification card switch`,
      );
      await changeNotificationStatusEntity(notification);

      fetchNotificationSettingsEntity();
    },
    [
      changeNotificationStatusEntity,
      entityName,
      fetchNotificationSettingsEntity,
    ],
  );

  const changeNotificationsStatusAllHandler = useCallback(
    async (group: { active: boolean; description?: string }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        group.active
          ? `Activate all ${entityName} notification statuses`
          : `Deactivate all ${entityName} notification statuses`,
      );

      await changeNotificationStatusAllGroup({
        active: group.active,
        description: group.description,
      });

      fetchNotificationSettingsEntity();
    },
    [
      changeNotificationStatusAllGroup,
      entityName,
      fetchNotificationSettingsEntity,
    ],
  );

  const enableNotificationHandler = useCallback(
    async (notification: { notificationTypeId?: string; enable: boolean }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `${entityName} notification enable card switch`,
      );

      await enableNotificationEntity(notification);

      fetchNotificationSettingsEntity();
    },
    [enableNotificationEntity, entityName, fetchNotificationSettingsEntity],
  );

  const enableNotificationsAllHandler = useCallback(
    async (group: { enable: boolean; description?: string }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        group.enable
          ? `Enable all ${entityName} level notifications`
          : `Disable all ${entityName} level notifications`,
      );

      await enableNotificationAllGroup({
        enable: group.enable,
        description: group.description,
      });

      fetchNotificationSettingsEntity();
    },
    [enableNotificationAllGroup, entityName, fetchNotificationSettingsEntity],
  );

  const changeUsersNotificationStatusHandler = useCallback(
    async (
      users: {
        userId: string;
        notificationTypeId: string;
        isAssigned: boolean;
      }[],
      type?: NotificationEntityType,
      notificationEntityId?: string,
    ) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Change ${entityName} user notification status`,
      );

      await changeUsersStatusNotificationEntity({
        users,
        type,
        notificationEntityId,
      });
      fetchNotificationSettingsAssignments();
    },
    [
      entityName,
      changeUsersStatusNotificationEntity,
      fetchNotificationSettingsAssignments,
    ],
  );
  const changeNotificationPropertyHandler = useCallback(
    async notification => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Change ${entityName} notification property`,
      );

      await changeNotificationPropertyEntity(notification);

      fetchNotificationSettingsEntity();
    },
    [
      changeNotificationPropertyEntity,
      entityName,
      fetchNotificationSettingsEntity,
    ],
  );

  return (
    <div>
      <SectionSpoiler
        header={t(translations.notificationSettingsPage.notificationsAndTeam, {
          entity: entityName,
        })}
        withIcons={false}
      >
        <NotificationSettingsComponent
          isFetching={isFetchingNotificationSettingsEntity}
          notifications={notificationSettingsEntity}
          teammatesList={notificationSettingsAssignments}
          teammatesListTotalCount={
            notificationSettingsAssignmentsMetadata?.totalCount
          }
          isFetchingTeammatesList={
            isFetchingNotificationSettingsAssignments &&
            !notificationSettingsAssignments
          }
          searchList={searchNotificationSettingsAssignments}
          isFetchingSearch={isFetchingSearchTeamAssignmentsEntity}
          changeNotificationsStatusAll={changeNotificationsStatusAllHandler}
          changeNotificationStatus={changeNotificationStatusHandler}
          changeUsersNotificationStatus={changeUsersNotificationStatusHandler}
          changeNotificationProperty={changeNotificationPropertyHandler}
          enableNotification={enableNotificationHandler}
          enableNotificationsAll={enableNotificationsAllHandler}
          searchTermHandler={setSearch}
          sortHandler={sortHandler}
          baseUrl={Routes.NotificationSettings.replace(
            ':organizationId/:tab',
            `${organizationId}/${tab}`,
          )}
          searchParams={searchParams()}
          paginationType={PaginationType.MAIN}
          removeAllOtherUserNotificationAssignments={
            removeAllOtherUserNotificationAssignments
          }
          getUserNotificationAssignmentsCall={
            getUserNotificationAssignmentsCall
          }
        />
      </SectionSpoiler>
      <UserAssignmentModal
        isOpen={userModalOpen}
        closeHandler={() => setUserModalOpen(false)}
        notification={notification}
        teamAssignment={teamAssignment}
        isLoading={isLoadingUserNotificationAssignments}
        tableData={getUserNotificationAssignmentsResponse?.data}
        changeUsersNotificationStatusHandler={
          changeUsersNotificationStatusHandler
        }
      />
    </div>
  );
};
