import { PopoverSize, Select } from 'app/shared/components/generic-ui';
import {
  CellAlignment,
  CellSize,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { useBatchApi } from 'app/shared/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useStyles } from './EntitiesDropdown.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';

interface IEntitiesDropdownProps {
  label: string;
  selectedValues?: string[];
  selectedItems?: {
    id: string;
    label: string;
  }[];
  onChangeSelectedEntities: (
    values: string[],
    items?: {
      id: string;
      label: string;
      additionalId?: string;
    }[],
  ) => void;
}

export const EventStatusDropdown = ({
  label,
  selectedItems,
  selectedValues,
  onChangeSelectedEntities,
}: IEntitiesDropdownProps) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<{ key: string; value: string }[]>([]);

  const columns = [
    {
      id: 'value',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
  ];

  const {
    historyBatchStatusesResponse,
    fetchHistoryBatchStatuses,
    isFetchingHistoryBatchStatuses,
  } = useBatchApi({});

  const renderCell = useCallback((rowData: any, cellId: string) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{rowData[cellId]}</div>;
  }, []);

  useEffect(() => {
    fetchHistoryBatchStatuses().then(res => {
      if (res.data) {
        setData(res.data.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchHistoryBatchStatuses]);

  const changeSelectedValuesHandler = useCallback(
    values => {
      const currentItems = values.map((id: string) => {
        const element = historyBatchStatusesResponse?.data.find(
          el => el.value === id,
        );
        return element
          ? {
              id: element?.key || '',
              label: element?.value || '',
            }
          : selectedItems?.find(el => el.id === id) || {
              id: '',
              label: '',
            };
      });

      onChangeSelectedEntities(values, currentItems);
    },
    [
      onChangeSelectedEntities,
      historyBatchStatusesResponse?.data,
      selectedItems,
    ],
  );

  return (
    <div className={classes.selectContainer}>
      <CustomTypography variant="caption1" color="greyscale1">
        {label}
      </CustomTypography>

      <Select.Search
        searchQuery={searchQuery}
        isLoading={isFetchingHistoryBatchStatuses}
        valueId={'value'}
        labelId={'key'}
        options={data}
        selectedValues={selectedValues}
        selectedItems={selectedItems}
        onChangeSelectedValues={changeSelectedValuesHandler}
        onSearchHandler={value => {
          setSearchQuery(value);
          if (historyBatchStatusesResponse?.data) {
            setData(
              historyBatchStatusesResponse.data.filter(el =>
                el.key.toLowerCase().includes(value.toLowerCase()),
              ),
            );
          }
        }}
        popoverSize={PopoverSize.BIG}
        renderCell={renderCell}
        columns={columns}
        clickHandler={fetchHistoryBatchStatuses}
      />
    </div>
  );
};
