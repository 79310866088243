import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  adminRoutes,
  memberRoutes,
  NotificationEntityType,
  Routes,
  superAdminRoutes,
} from 'types';
import './MainRouter.scss';
import { Layout } from '../layout/Layout';
import { LoginPage } from 'app/pages/login';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useAuth } from 'app/shared/providers';
import { useProfileApi } from '../../hooks';
import { RouterMiddleware } from './RouterMiddleware';
import LoginWrapper from 'app/pages/login/LoginWrapper';

export const MainRouter = () => {
  const {
    isReady,
    isAuthenticated,
    supplierId,
    isAdmin,
    isSuperAdmin,
    isMember,
  } = useAuth();
  const { fetchProfile } = useProfileApi({});

  const userRoutes = useMemo(() => {
    return isSuperAdmin
      ? superAdminRoutes
      : isAdmin
      ? adminRoutes
      : !isSuperAdmin && !isAdmin && isMember
      ? memberRoutes
      : [];
  }, [isAdmin, isMember, isSuperAdmin]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfile();
    }
  }, [isAuthenticated, fetchProfile]);

  return isReady ? (
    <BrowserRouter>
      <RouterMiddleware>
        <AuthenticatedTemplate>
          <Switch>
            {userRoutes.map((route, index) => {
              if (!route?.redirect) {
                return (
                  <Route
                    key={index}
                    exact
                    path={route.path}
                    render={() => {
                      return (
                        <Layout
                          withSideNavigation={
                            route.path !== Routes.Not_Found &&
                            route.path !== Routes.ChatGPT
                          }
                        >
                          <route.component />
                        </Layout>
                      );
                    }}
                  />
                );
              } else {
                return (
                  <Route key={index} exact path={Routes.Home}>
                    <Redirect
                      to={
                        route.redirect === Routes.Organizations
                          ? route.redirect
                          : route.redirect
                              .replace(':organizationId', supplierId)
                              .replace(
                                ':entityType',
                                NotificationEntityType.PROJECT.toString(),
                              )
                      }
                    />
                  </Route>
                );
              }
            })}
          </Switch>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Switch>
            <Route exact path={Routes.Home}>
              <Redirect to={Routes.Login} />
            </Route>

            <Route exact path={Routes.Login} render={() => <LoginPage />} />

            <Route
              exact
              path={Routes.Not_Found}
              render={() => (
                <LoginWrapper>
                  <LoginPage />
                </LoginWrapper>
              )}
            />
          </Switch>
        </UnauthenticatedTemplate>
      </RouterMiddleware>
    </BrowserRouter>
  ) : null;
};
