import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import React, { useCallback, useState } from 'react';
import { IAcknowledgement, Routes } from 'types';
import { useStyles } from './Acknowledgement.styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AcknowledgedIcon } from 'assets/check-circle.svg';
import { ReactComponent as AcknowledgedIconWithNotes } from 'assets/file-text.svg';
import { firebaseAnalytics } from 'app/shared/analytics';
import { formatTimeWithDateIfNotToday } from 'app/shared/utils/formatTimeWithDate';

interface IAcknowledgementProps {
  acknowledgement: IAcknowledgement;
}

export const Acknowledgement = ({ acknowledgement }: IAcknowledgementProps) => {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  const { t } = useTranslation();

  const handleComponentClick = useCallback(() => {
    if (acknowledgement.acknowledgementNote) {
      firebaseAnalytics.logPressEvent(
        Routes.SinglePourEvent,
        'Batch event card acknowledgement spoiler',
      );

      setIsExpand(prevState => !prevState);
    }
  }, [acknowledgement]);

  return (
    <>
      <div
        className={
          acknowledgement.acknowledgementNote
            ? classes.headerClickable
            : classes.header
        }
        onClick={handleComponentClick}
      >
        <div className={classes.text}>
          <div className={classes.title}>
            {acknowledgement.acknowledgementNote ? (
              <AcknowledgedIconWithNotes />
            ) : (
              <AcknowledgedIcon />
            )}

            <div className={classes.paddingContainer}>
              <CustomTypography variant="bodySmall" bold color="mbsBlue">
                {acknowledgement.acknowledgementNote
                  ? t(translations.batchEventCard.acknowledgedWithNote)
                  : t(translations.batchEventCard.acknowledged)}
              </CustomTypography>
            </div>
          </div>

          <CustomTypography variant="caption2" bold color="greyscale2">
            {`${t(translations.batchEventCard.by)} ${
              acknowledgement.firstName || '-'
            } ${acknowledgement.lastName || ''} ${t(
              translations.batchEventCard.at,
            )} ${formatTimeWithDateIfNotToday(
              acknowledgement.acknowledgedDateTimeUtc,
            )}`}
          </CustomTypography>
        </div>

        {acknowledgement.acknowledgementNote && (
          <div className={classes.arrowContainer}>
            {isExpand ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            )}
          </div>
        )}
      </div>
      {isExpand && (
        <div className={classes.noteContainer}>
          <CustomTypography variant="bodyRegular" color="accentDark">
            {acknowledgement.acknowledgementNote}
          </CustomTypography>
        </div>
      )}
    </>
  );
};
