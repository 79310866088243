import { useState, useMemo, useCallback, useEffect } from 'react';
import { translations } from 'app/locales/i18n';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { useBatchApi, useOrganizationsApi } from 'app/shared/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStyles } from './BatchesPage.styles';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { BatchList } from './components';
import { FiltersBlock } from './components/FiltersBlock';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { IHistoryRecord, IMetaData, Routes } from 'types';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as RadioIcon } from 'assets/radio_icon.svg';
import { ReactComponent as RadioEmpty } from 'assets/radio_empty.svg';
import { useAuth } from 'app/shared/providers';
import { useHistory } from 'react-router-dom';

const emptyRows = 0;

export const BatchesPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organizationId }: { organizationId: string } = useParams();
  const { isMember } = useAuth();
  const history = useHistory();

  const [batchList, setBatchList] = useState<IHistoryRecord[]>();
  const [totalCount, setTotalCount] = useState<number>(emptyRows);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checked, setChecked] = useState(true);
  const [toJobRemaining, setToJobRemaining] = useState(false);
  const [isUserAssignedEntities, setUserAssignedEntities] = useState(isMember);

  const { organizationDetails } = useOrganizationsApi({
    organizationId: organizationId || '',
  });

  const { fetchMapHeader, mapHeader, isFetchingMapHeader } = useBatchApi({
    organizationId: organizationId,
  });

  useEffect(() => {
    fetchMapHeader();
  }, [fetchMapHeader]);

  const changeCheckboxHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);

      history.push({
        pathname: Routes.Batches.replace(
          ':organizationId/:page/:perPage',
          `${organizationId}/1/20`,
        ),
        search: location.search,
      });
    },
    [history, organizationId],
  );

  const checkbox = useMemo(() => {
    return (
      <div className={classes.checkbox}>
        <Checkbox
          checkedIcon={<RadioIcon />}
          icon={<RadioEmpty />}
          onChange={changeCheckboxHandler}
          checked={checked}
        />
        <CustomTypography variant="bodySmall" color="accentDark">
          {t(translations.batchesPage.showBatchesWithStatuses)}
        </CustomTypography>
      </div>
    );
  }, [changeCheckboxHandler, checked, classes.checkbox, t]);

  const refreshHandler = useCallback(() => {
    setRefresh(prevState => !prevState);
  }, []);

  const changeUserAssignedEntitiesHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserAssignedEntities(event.target.checked);

      history.push({
        pathname: Routes.Batches.replace(
          ':organizationId/:page/:perPage',
          `${organizationId}/1/20`,
        ),
        search: location.search,
      });
    },
    [history, organizationId],
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <CustomTypography variant="header3" bold color="accentDark">
          {`${organizationDetails?.supplierName || ''} ${t(
            translations.batchesPage.batches,
          )}`}
        </CustomTypography>

        <div className={classes.checkbox}>
          <Checkbox
            checkedIcon={<RadioIcon />}
            icon={<RadioEmpty />}
            onChange={changeUserAssignedEntitiesHandler}
            checked={isUserAssignedEntities}
          />

          <CustomTypography variant="bodySmall" color="accentDark">
            {t(translations.batchesPage.showUserAssignedEntities)}
          </CustomTypography>
        </div>
      </div>

      <div>
        <FiltersBlock
          withStatusesOnly={checked}
          loadingHandler={setIsLoading}
          onChangeTableData={(
            data?: IHistoryRecord[],
            metadata?: IMetaData,
          ) => {
            setBatchList(data);
            setTotalCount(metadata?.totalCount || emptyRows);
          }}
          refresh={refresh}
          refreshHandler={refreshHandler}
          isUserAssignedEntities={isUserAssignedEntities}
          toJobRemaining={toJobRemaining}
        />

        <SectionSpoiler
          withIcons={false}
          header={t(translations.batchesPage.allBatches)}
          subHeaders={[{ element: checkbox }]}
        >
          {!isFetchingMapHeader && mapHeader ? (
            <BatchList
              batchList={batchList}
              isLoading={isLoading}
              rowsTotal={totalCount}
              statuses={mapHeader}
              refreshHandler={refreshHandler}
              setToJobRemaining={setToJobRemaining}
            />
          ) : (
            <CircularProgress withLabel />
          )}
        </SectionSpoiler>
      </div>
    </div>
  );
};
