import { format } from 'date-fns';

const timeFormat = 'hh:mm aaa';
const dateTimeFormat = 'hh:mm aaa, dd MMM yyyy ';
const zero = 0;

export function formatTimeWithDateIfNotToday(
  date: string | Date | null | undefined,
) {
  if (!date) return '-';
  const currentDate = new Date(date);
  const newDate = typeof date === 'string' ? new Date(date) : date;

  return currentDate.setHours(zero, zero, zero, zero) !==
    new Date().setHours(zero, zero, zero, zero)
    ? format(newDate, dateTimeFormat)
    : format(newDate, timeFormat);
}
