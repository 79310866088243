import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useStyles } from './BatchEventCard.styles';
import { IHistoryRecord, HistoryType, Routes, IProbeValue } from 'types';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as AlertIcon } from 'assets/alert-circle.svg';
import { NotificationChart } from './Chart';
import { ChartLimitedRange } from 'app/shared/components/generic-ui/Chart';
import { Acknowledgement } from './Acknowledgement';
import { ReactComponent as AcknowledgedIcon } from 'assets/check-circle.svg';
import {
  IAcknowledgement,
  INote,
  INotificationSent,
  NotificationCode,
} from 'types/general';
import { CardProbeDataSection } from './CardProbeDataSection/CardProbeDataSection';
import { firebaseAnalytics } from 'app/shared/analytics';
import { useIsMount } from 'app/shared/utils';
import { themeColors } from 'app/shared/theme';
import { useBatchApi, useProbeDataFormatter } from 'app/shared/hooks';
import { SwitchNotificationType } from './SwitchNotificationType';
import { useParams } from 'react-router-dom';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { Switch } from 'app/shared/components/generic-ui';
import { useAuth } from 'app/shared/providers';
import { Notifications } from './Notifications';
import { Note } from './Note';
import { ReactComponent as AddIcon } from 'assets/add_compr_str.svg';
import { ReactComponent as AcknowledgedIconWithNotes } from 'assets/file-text.svg';

import { Grid } from '@material-ui/core';
import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { TeammateTextInput } from 'app/shared/components/generic-ui/TextInput/TeammateTextInput';
import { formatTimeWithDateIfNotToday } from 'app/shared/utils/formatTimeWithDate';

interface IBatchEventCard {
  batchEvent: IHistoryRecord;
  isExpanded: boolean;
  slumpRange: {
    minValue: number;
    maxValue: number;
  };
  focusTargetCard: (offset: number) => void;
  targetSlump: IProbeValue;
  returnedConcreteMeasurement: string;
  invalidateCardHandler: (
    e: ChangeEvent<HTMLInputElement>,
    eventName: string,
    id?: number,
  ) => void;
  viewNotificationsSentHandler: (
    notifications: INotificationSent[],
    notificationName: string,
    historyRecordId: string,
    eventStatusCode: string,
  ) => void;
  handleAddNote: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    batchEvent: IHistoryRecord,
  ) => void;
  itemToAddNote?: IHistoryRecord;
  cancelNote?: () => void;
  crn: string;
  refetchBatchHistory: () => void;
  index: number;
}

const minCardHeight = 240;
const decimalPlaces = 1;

export const BatchEventCard = ({
  batchEvent,
  slumpRange,
  isExpanded,
  targetSlump,
  focusTargetCard,
  returnedConcreteMeasurement,
  invalidateCardHandler,
  viewNotificationsSentHandler,
  handleAddNote,
  itemToAddNote,
  cancelNote,
  crn,
  refetchBatchHistory,
  index,
}: IBatchEventCard) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(isExpanded);
  const [isCardSpoilerClicked, setIsCardSpoilerClicked] = useState(false);
  const refToScroll = useRef<HTMLDivElement>(null);
  const isMount = useIsMount();
  const { formatProbeValue } = useProbeDataFormatter();
  const { organizationId }: { organizationId: string } = useParams();
  const { userId, isAdmin, isSuperAdmin } = useAuth();
  const [currentNote, setCurrentNote] = useState('');
  const [receiverIds, setReceviersIds] = useState<string[]>([]);

  const {
    updatedHistoryRecord,
    isUpdatingNotificationType,
    updateNotificationType,
    addNote,
  } = useBatchApi({
    organizationId: organizationId,
    queryParameters: {
      crn,
      projectId: '1',
    },
  });

  useEffect(() => {
    setIsExpand(isExpanded);
  }, [isExpanded]);

  const onNoteSubmit = useCallback(async () => {
    await addNote({
      historyRecordId: batchEvent.historyRecordId.toString(),
      noteText: currentNote,
      receiverIds,
      crn: crn,
    });
    await refetchBatchHistory();

    cancelNote?.();
  }, [
    addNote,
    batchEvent.historyRecordId,
    currentNote,
    crn,
    refetchBatchHistory,
    cancelNote,
    receiverIds,
  ]);

  const currentBatchEvent = useMemo(() => {
    return updatedHistoryRecord || batchEvent;
  }, [batchEvent, updatedHistoryRecord]);

  useEffect(() => {
    if (!isMount && isCardSpoilerClicked) {
      focusTargetCard(
        isExpand
          ? refToScroll.current?.offsetHeight || minCardHeight
          : -(refToScroll.current?.offsetHeight || minCardHeight),
      );
    }
  }, [focusTargetCard, isCardSpoilerClicked, isExpand, isMount]);

  const { hasAcknowledgements, hasNotes } = useMemo(() => {
    const result = {
      hasAcknowledgements: false,
      hasNotes: false,
    };

    for (let i = 0; i < currentBatchEvent?.acknowledgements?.length; i++) {
      const item = currentBatchEvent.acknowledgements[i];

      if (item.acknowledgementNote?.length) {
        result.hasNotes = true;
      } else {
        result.hasAcknowledgements = true;
      }

      if (result.hasNotes && result.hasAcknowledgements) {
        break;
      }
    }

    return result;
  }, [currentBatchEvent]);

  const isEventType = useMemo(() => {
    return currentBatchEvent.historyType === HistoryType.EVENT;
  }, [currentBatchEvent.historyType]);

  const isExpandable = useMemo(() => {
    return (
      (currentBatchEvent.workability.value !== null && isEventType) ||
      !isEventType ||
      currentBatchEvent.isManualProbe ||
      !!currentBatchEvent?.notes?.length
    );
  }, [
    currentBatchEvent.isManualProbe,
    currentBatchEvent.workability.value,
    isEventType,
    currentBatchEvent?.notes?.length,
  ]);

  const formattedEventTimestamp = useMemo(
    () => formatTimeWithDateIfNotToday(currentBatchEvent.eventDateTimeUtc),
    [currentBatchEvent.eventDateTimeUtc],
  );

  const formattedProbeTimestamp = useMemo(
    () => formatTimeWithDateIfNotToday(currentBatchEvent.probeTimestampUtc),
    [currentBatchEvent.probeTimestampUtc],
  );

  const handleCardSpoilerClick = useCallback(() => {
    if (isExpandable) {
      firebaseAnalytics.logPressEvent(
        Routes.SinglePourEvent,
        'Batch event card spoiler',
      );

      setIsCardSpoilerClicked(true);
      setIsExpand(prevState => !prevState);
    }
  }, [isExpandable]);

  const currentSlumpRange = useMemo(() => {
    return currentBatchEvent.eventStatusCode ===
      NotificationCode.SLUMP_OUT_OF_RANGE_AT_JOB && targetSlump?.value
      ? {
          minValue: +formatProbeValue(
            targetSlump?.value - currentBatchEvent.notificationRangeValue,
            decimalPlaces,
          ),
          maxValue: +formatProbeValue(
            targetSlump?.value + currentBatchEvent.notificationRangeValue,
            decimalPlaces,
          ),
        }
      : slumpRange;
  }, [
    currentBatchEvent.eventStatusCode,
    currentBatchEvent.notificationRangeValue,
    formatProbeValue,
    slumpRange,
    targetSlump?.value,
  ]);

  const isReturnedOrRejected = useMemo(() => {
    return (
      currentBatchEvent.eventStatusCode ===
        NotificationCode.RETURNED_CONCRETE ||
      currentBatchEvent.eventStatusCode === NotificationCode.REJECTED_LOAD
    );
  }, [currentBatchEvent.eventStatusCode]);

  const showLimitedChart = useMemo(() => {
    return (
      currentBatchEvent.workability.value &&
      targetSlump?.value &&
      currentSlumpRange.minValue &&
      currentSlumpRange.maxValue &&
      (isEventType ||
        currentBatchEvent.eventStatusCode ===
          NotificationCode.SLUMP_OUT_OF_RANGE ||
        currentBatchEvent.eventStatusCode ===
          NotificationCode.SLUMP_OUT_OF_RANGE_AT_JOB)
    );
  }, [
    currentBatchEvent.eventStatusCode,
    currentBatchEvent.workability.value,
    currentSlumpRange.maxValue,
    currentSlumpRange.minValue,
    isEventType,
    targetSlump?.value,
  ]);

  const handleSwitchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      invalidateCardHandler(
        e,
        batchEvent.historyRecordName,
        batchEvent.manualProbeDataId,
      );
    },
    [
      batchEvent.historyRecordName,
      batchEvent.manualProbeDataId,
      invalidateCardHandler,
    ],
  );

  return (
    <div className={classes.container} ref={refToScroll}>
      {isUpdatingNotificationType ? (
        <div className={classes.progress}>
          <CircularProgress withLabel />
        </div>
      ) : (
        <>
          {isExpand &&
            ((currentBatchEvent.workability.value !== null && isEventType) ||
              !isEventType ||
              currentBatchEvent.isManualProbe) && (
              <>
                <div className={classes.header}>
                  <div className={classes.headerSections}>
                    <div className={classes.batchAge}>
                      <CustomTypography variant="caption2" color="greyscale2">
                        {`${t(
                          translations.batchEventCard.currentMeasurementTime,
                        )}: `}
                      </CustomTypography>
                      <div className={classes.ageContainerLight}>
                        <CustomTypography
                          variant="caption2"
                          color="accentLight"
                        >
                          {formattedProbeTimestamp}
                        </CustomTypography>
                      </div>
                    </div>
                  </div>

                  <div className={classes.headerSections}>
                    <div className={classes.batchAge}>
                      <CustomTypography variant="caption2" color="greyscale2">
                        {`${t(translations.batchEventCard.batchAge)}: `}
                      </CustomTypography>
                      <div className={classes.ageContainerLight}>
                        <CustomTypography
                          variant="caption2"
                          color="accentLight"
                        >
                          {`${currentBatchEvent.batchAge || '-'} ${
                            currentBatchEvent.batchAge
                              ? t(translations.batchEventCard.min)
                              : ''
                          }`}
                        </CustomTypography>
                      </div>
                    </div>
                  </div>

                  {batchEvent.isManualProbe ? (
                    <div className={classes.headerSections}>
                      <div className={classes.validity}>
                        <div className={classes.labelWrapper}>
                          <CustomTypography
                            variant="caption2"
                            color="greyscale2"
                          >
                            {`${t(translations.batchEventCard.validity)}: `}
                          </CustomTypography>
                        </div>

                        <div className={classes.switchWrapper}>
                          <Switch
                            disabled={
                              !(
                                userId === batchEvent?.createdBy ||
                                isSuperAdmin ||
                                isAdmin
                              )
                            }
                            checked={!batchEvent.isInvalid}
                            onChange={handleSwitchChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className={classes.body}>
                  <CardProbeDataSection batchEvent={currentBatchEvent} />
                  {!isEventType && (
                    <>
                      <div className={classes.notificationDescription}>
                        <CustomTypography
                          variant="bodySmall"
                          color="accentDark"
                          style={{ whiteSpace: 'inherit' }}
                        >
                          {currentBatchEvent.historyRecordDescription}
                        </CustomTypography>
                      </div>
                      {currentBatchEvent.eventStatusCode !==
                        NotificationCode.SLUMP_OUT_OF_RANGE &&
                        currentBatchEvent.eventStatusCode !==
                          NotificationCode.SLUMP_OUT_OF_RANGE_AT_JOB && (
                          <NotificationChart
                            historyRecord={currentBatchEvent}
                            returnedConcreteMeasurement={
                              returnedConcreteMeasurement
                            }
                          />
                        )}
                    </>
                  )}

                  {showLimitedChart ? (
                    <ChartLimitedRange
                      rangeMin={currentSlumpRange.minValue}
                      rangeMax={currentSlumpRange.maxValue}
                      value={currentBatchEvent.workability.value}
                      units={currentBatchEvent.workability.measurement}
                    />
                  ) : null}
                </div>

                {isReturnedOrRejected && (
                  <SwitchNotificationType
                    value={currentBatchEvent.eventStatusCode}
                    onChange={e => {
                      firebaseAnalytics.logPressEvent(
                        Routes.SinglePourEvent,
                        'Switch notification type(Returned or Rejected)',
                      );

                      updateNotificationType({
                        historyDataId: currentBatchEvent.historyRecordId,
                        notificationCode: e.target.value,
                      });
                    }}
                  />
                )}
              </>
            )}

          {isExpand &&
            currentBatchEvent?.acknowledgements?.map(
              (acknowledgement: IAcknowledgement, key: any) => (
                <Acknowledgement key={key} acknowledgement={acknowledgement} />
              ),
            )}

          {isExpand &&
            currentBatchEvent?.notes?.map((note: INote, index: any) => (
              <Note key={index} note={note} index={index} />
            ))}

          {isExpand && !isEventType && (
            <div className={classes.headerSections}>
              <Notifications
                eventStatusCode={currentBatchEvent.eventStatusCode}
                isExpanded={isExpanded}
                notifications={currentBatchEvent.notifications}
                origin={currentBatchEvent.notificationEntityType}
                viewNotificationsSentHandler={notifications =>
                  viewNotificationsSentHandler(
                    notifications,
                    currentBatchEvent.historyRecordName,
                    currentBatchEvent.historyRecordId,
                    currentBatchEvent.eventStatusCode,
                  )
                }
                notificationEntity={
                  currentBatchEvent.notificationEntityBaseModel
                }
              />
            </div>
          )}
          {itemToAddNote?.historyRecordId === batchEvent.historyRecordId && (
            <div className={classes.inputsContainer}>
              <Grid container lg={12} md={12}>
                <Grid item md={12} lg={12}>
                  <TeammateTextInput
                    noteChange={setCurrentNote}
                    setReceviersIds={setReceviersIds}
                    index={index}
                  />
                </Grid>
                <Grid item md={6} lg={6}>
                  <Button
                    variant="secondaryCancel"
                    className={classes.cancel}
                    onClick={cancelNote}
                  >
                    <CustomTypography variant="bodySmall" color="mbsBlue">
                      {t(translations.dosageModal.cancel)}
                    </CustomTypography>
                  </Button>
                </Grid>
                <Grid item md={6} lg={6}>
                  <Button variant="primaryBig" onClick={onNoteSubmit}>
                    <CustomTypography variant="bodySmall" color="white">
                      {t(translations.dosageModal.save)}
                    </CustomTypography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          <div className={classes.footer} onClick={handleCardSpoilerClick}>
            <div className={classes.headerSections}>
              {!isEventType && (
                <div className={classes.notificationIcon}>
                  <AlertIcon
                    height={20}
                    stroke={
                      !isEventType && !currentBatchEvent.canBeAcknowledged
                        ? themeColors.greyscale2
                        : themeColors.systemRed
                    }
                  />
                </div>
              )}

              <CustomTypography
                variant="mobileHeader6"
                bold
                color={
                  isEventType
                    ? 'accentDark'
                    : currentBatchEvent.canBeAcknowledged
                    ? 'systemRed'
                    : 'greyscale2'
                }
              >
                {currentBatchEvent.historyRecordName}
              </CustomTypography>

              {batchEvent.isManualProbe ? (
                <div className={classes.manualData}>
                  <div className={classes.manualDataLabel}>
                    <CustomTypography variant="caption2" bold color="white">
                      {t(translations.batchEventCard.manualTestData)}
                    </CustomTypography>
                  </div>
                </div>
              ) : null}

              <div className={classes.valueContainer}>
                <CustomTypography variant="caption2" bold color="greyscale2">
                  {formattedEventTimestamp}
                </CustomTypography>
              </div>

              <div className={classes.headerSections}>
                {!isEventType && !isExpand && (
                  <div className={classes.iconsContainer}>
                    {hasNotes && (
                      <AcknowledgedIconWithNotes
                        className={classes.iconPadding}
                      />
                    )}
                    {hasAcknowledgements && (
                      <AcknowledgedIcon className={classes.iconPadding} />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.icons}>
              {!itemToAddNote && (
                <div
                  className={classes.arrowContainer}
                  onClick={e => handleAddNote(e, batchEvent)}
                >
                  <AddIcon className={classes.expandIcon} />
                </div>
              )}
              {isExpandable && (
                <div className={classes.arrowContainer}>
                  {!isExpand ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </div>
              )}
              {currentBatchEvent?.notes?.length ? (
                <div className={classes.arrowContainer}>
                  <AcknowledgedIconWithNotes />
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
