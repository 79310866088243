import { makeStyles } from '@material-ui/core';
import { themeColors } from 'app/shared/theme/theme';

export const useStyles = makeStyles({
  cell: {
    padding: '0px 16px',
    color: themeColors.greyscale2,
  },
  cellWithoutCheckbox: {
    padding: '12px 16px 13px 16px',
    color: themeColors.greyscale2,
    '&:last-child': {
      padding: '12px 16px 13px 16px',
    },
  },
  cellVariant: {
    padding: '0px 0px 0px 16px',
  },

  withoutPadding: {
    padding: 5,
  },
  label: {
    width: '100%',
    padding: '0px ',
    justifyContent: 'space-between',
    color: `${themeColors.greyscale2} !important`,
  },
  checkedIcon: {
    color: `${themeColors.mbsBlue} !important`,
  },
  icon: {
    color: themeColors.greyscale,
    '&:hover': {
      backgroundColor: `${themeColors.highlightBlue} !important`,
    },
  },
  size: {
    width: '10px',
  },
  header: {
    backgroundColor: themeColors.greyscale4,
  },
  headerActive: {
    backgroundColor: themeColors.mbsBlue,
  },
  headerVariant: {
    backgroundColor: themeColors.white,
  },
  tableCellLight: {
    padding: '16px 16px 0px 16px',
    borderBottom: 'none',
    fontWeight: 'normal',
  },
  tableCellLightWithoutPadding: {
    padding: '10px 0px 0px 0px !important',
    borderBottom: 'none',
    fontWeight: 'normal',
  },
  tableCellDark: {
    fontWeight: 'bold',
  },
  justify: {
    width: '40%',
  },
  select: {
    padding: '8px 10px',
    fontSize: '14px',
    lineHeight: '20px',
    height: 17,
    width: 120,
  },
  selectNoHeight: {
    height: 'unset !important',
  },
  root: {
    width: 120,
  },
  buttonsCell: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-10px',
  },
  borderContainer: {
    height: '100%',
    padding: 10,
    position: 'absolute',
    left: '-13px',
    top: 0,
    boxSizing: 'border-box',
  },
  borderLeft: {
    borderLeft: `1px solid ${themeColors.greyscale3}`,
    width: 2,
    flex: 1,
    height: '100%',
  },
  head: {
    position: 'sticky',
    top: 0,
    zIndex: 8999,
  },
});
