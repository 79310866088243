import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './BatchHistory.styles';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { BatchEventCard } from './BatchEventCard/BatchEventCard';
import {
  HistoryType,
  IChangeProbeValidity,
  IHistoryRecord,
  IHistoryRecordExtended,
  INotificationSent,
  NotificationEntityType,
  Routes,
} from 'types';
import { ReactComponent as AddIcon } from 'assets/add_compr_str.svg';
import { useParams } from 'react-router-dom';
import { useManualProbeApi } from 'app/shared/hooks';
import { ConfirmationPage } from '../ConfirmationPage';
import { ReactComponent as RefreshIcon } from 'assets/refresh.svg';
import { CircularProgress } from 'app/shared/components/generic-ui/CircularProgress';
import { firebaseAnalytics } from 'app/shared/analytics';
import { NotificationsSentModal } from './NotificationsSentModal';

interface IBatchHistory {
  batchHistory: IHistoryRecordExtended;
  focusTargetCard: (offset: number) => void;
  addManualDataHandler: () => void;
  refetchBatchHistory: () => void;
  hideTabsHandler: (value: boolean) => void;
  viewSentNotificationsHandler: () => void;
  isLoading?: boolean;
  isSentNotificationsModalOpen: boolean;
  crn: string;
}

export const BatchHistory = ({
  batchHistory,
  isLoading,
  focusTargetCard,
  addManualDataHandler,
  refetchBatchHistory,
  hideTabsHandler,
  viewSentNotificationsHandler,
  isSentNotificationsModalOpen,
  crn,
}: IBatchHistory) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organizationId }: { organizationId: string } = useParams();
  const [itemToAddNote, setItemToAddNote] = useState<IHistoryRecord>();
  const [itemToInvalidate, setItemToInvalidate] =
    useState<IChangeProbeValidity>();
  const [targetEventName, setTargetEventName] = useState('');
  const historyRecordId = new URLSearchParams(location.search).get(
    'historyRecordId',
  );
  const [notificationsSent, setNotificationsSent] = useState<
    INotificationSent[]
  >([]);
  const [targetNotificationName, setTargetNotificationName] = useState('');
  const [targetHistoryRecordId, setTargetHistoryRecordId] = useState('');
  const [eventStatusCode, setEventStatusCode] = useState('');

  const { invalidateManualProbe } = useManualProbeApi({
    organizationId: organizationId,
  });

  const expandedCardIndex = useMemo(() => {
    let cardIndex: number | null = null;
    const expandedElement = batchHistory.batchHistoryDetails.find(
      el =>
        el.historyRecordId ===
        (targetHistoryRecordId ? targetHistoryRecordId : historyRecordId),
    );

    if (targetHistoryRecordId && expandedElement) {
      cardIndex = batchHistory.batchHistoryDetails.indexOf(expandedElement);
    } else {
      if (historyRecordId && expandedElement) {
        cardIndex = batchHistory.batchHistoryDetails.indexOf(expandedElement);
      } else {
        for (let i = 0; i < batchHistory.batchHistoryDetails.length; i++) {
          const element = batchHistory.batchHistoryDetails[i];

          if (
            (element.workability.value !== null &&
              element.historyType === HistoryType.EVENT) ||
            element.historyType === HistoryType.NOTIFICATION ||
            element.isManualProbe
          ) {
            cardIndex = i;
            break;
          }
        }
      }
    }

    return cardIndex;
  }, [
    batchHistory.batchHistoryDetails,
    historyRecordId,
    targetHistoryRecordId,
  ]);

  const canAddManualData = useMemo(() => {
    const isVoided = !!batchHistory?.batchHistoryDetails?.find(
      el => el.eventStatusCode === 'VOIDED',
    );

    return !isVoided;
  }, [batchHistory.batchHistoryDetails]);

  const invalidateCardHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, eventName: string, id?: number) => {
      if (id) {
        setItemToInvalidate({
          manualProbeDataId: id,
          isInvalid: !e.target.checked,
        });

        setTargetEventName(eventName);

        hideTabsHandler(true);
      }
    },
    [hideTabsHandler],
  );

  const backHandler = useCallback(() => {
    setItemToInvalidate(undefined);
    hideTabsHandler(false);
  }, [hideTabsHandler]);

  const cancelNote = () => {
    setItemToAddNote(undefined);
  };

  const handleAddNote = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    batchEvent: IHistoryRecord,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setItemToAddNote(batchEvent);
  };

  const confirmHandler = useCallback(async () => {
    if (itemToInvalidate) {
      firebaseAnalytics.logPressEvent(
        Routes.SinglePourEvent,
        'Invalidate manual test data',
      );

      await invalidateManualProbe(itemToInvalidate);
      refetchBatchHistory();

      backHandler();
    }
  }, [
    backHandler,
    invalidateManualProbe,
    itemToInvalidate,
    refetchBatchHistory,
  ]);

  const refreshBatchHistoryHandler = useCallback(() => {
    return refetchBatchHistory();
  }, [refetchBatchHistory]);

  return !notificationsSent.length && itemToInvalidate ? (
    <ConfirmationPage
      backHandler={backHandler}
      confirmHandler={confirmHandler}
      eventName={targetEventName}
    />
  ) : !!notificationsSent.length &&
    !itemToInvalidate &&
    isSentNotificationsModalOpen ? (
    <NotificationsSentModal
      notifications={notificationsSent}
      notificationName={targetNotificationName}
      eventStatusCode={eventStatusCode}
    />
  ) : (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.progress}>
          <CircularProgress withLabel={true} />
        </div>
      ) : null}

      <div className={classes.container}>
        <div className={classes.subheaderContainer}>
          <div className={classes.subHeader}>
            <CustomTypography variant="header6" bold color="accentDark">
              {t(translations.batchDetailsModal.batchHistory)}
            </CustomTypography>
          </div>

          <div className={classes.buttonsWrapper}>
            <div
              onClick={refreshBatchHistoryHandler}
              className={classes.refreshButton}
            >
              <RefreshIcon className={classes.refreshIcon} />
              <CustomTypography variant="subtitle2" bold color="mbsBlue">
                {t(translations.pourEventPage.refresh)}
              </CustomTypography>
            </div>

            {canAddManualData ? (
              <div className={classes.button} onClick={addManualDataHandler}>
                <AddIcon className={classes.icon} />
                <CustomTypography variant="subtitle2" bold color="mbsBlue">
                  {t(translations.batchDetailsModal.addManualTestData)}
                </CustomTypography>
              </div>
            ) : null}
          </div>
        </div>

        {batchHistory.batchHistoryDetails.map((event, index) => (
          <BatchEventCard
            key={index}
            index={index}
            isExpanded={index === expandedCardIndex}
            batchEvent={event}
            slumpRange={batchHistory.slumpRange}
            targetSlump={batchHistory.targetSlump}
            handleAddNote={handleAddNote}
            focusTargetCard={focusTargetCard}
            returnedConcreteMeasurement={
              batchHistory?.totalTicketed?.measurement || ''
            }
            crn={crn}
            refetchBatchHistory={refetchBatchHistory}
            cancelNote={cancelNote}
            itemToAddNote={itemToAddNote}
            invalidateCardHandler={invalidateCardHandler}
            viewNotificationsSentHandler={(
              notifications: INotificationSent[],
              notificationName: string,
              historyRecordId: string,
              eventStatusCode: string,
            ) => {
              setNotificationsSent(notifications);
              setTargetNotificationName(notificationName);
              setTargetHistoryRecordId(historyRecordId);
              setEventStatusCode(eventStatusCode);
              viewSentNotificationsHandler();
            }}
          />
        ))}
      </div>
    </div>
  );
};
