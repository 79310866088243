import { Avatar } from 'app/shared/components/generic-ui/Avatar/Avatar';
import { Table } from 'app/shared/components/generic-ui/Table/Table';
import {
  CellAlignment,
  CellSize,
  TableHeaderType,
} from 'app/shared/components/generic-ui/Table/Table.types';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import {
  getNotificationEntityName,
  getNotificationSettingsTab,
  getUserInitials,
} from 'app/shared/utils';
import { useCallback } from 'react';
import {
  INotificationEntity,
  INotificationSent,
  NotificationEntityType,
  Routes,
} from 'types';
import { useStyles } from './NotificationsSentModal.styles';
import { useHistory, useParams } from 'react-router-dom';
import { translations } from 'app/locales/i18n';
import { themeColors } from 'app/shared/theme';
import { ReactComponent as NotificationSettingsIcon } from 'assets/settings.svg';
import { useTranslation } from 'react-i18next';

interface INotificationsSentModalProps {
  notifications: INotificationSent[];
  notificationName: string;
  eventStatusCode: string;
}

export const NotificationsSentModal = ({
  notifications,
  notificationName,
  eventStatusCode,
}: INotificationsSentModalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { organizationId }: { organizationId: string } = useParams();

  const defaultColumns = [
    {
      id: 'name',
      label: 'Name',
      alignment: CellAlignment.LEFT,
      size: CellSize.MEDIUM,
    },
    {
      id: 'userEmail',
      label: 'Email',
      alignment: CellAlignment.RIGHT,
      size: CellSize.SMALL,
    },
    {
      id: 'notificationEntityBaseModel',
      label: '',
      alignment: CellAlignment.RIGHT,
      size: CellSize.SMALL,
    },
  ];

  const notificationSettingsClickHandler = useCallback(
    (
      notificationEntity: INotificationEntity & {
        notificationEntityType: NotificationEntityType;
      },
    ) => {
      localStorage.setItem(
        notificationEntity.notificationEntityType.toString(),
        JSON.stringify(notificationEntity),
      );
      const searchParams = new URLSearchParams({
        ['code']: eventStatusCode,
      });

      history.push({
        pathname: Routes.NotificationSettings.replace(
          ':organizationId/:tab/:page/:perPage/:alternativePage/:alternativePerPage',
          `${organizationId}/${getNotificationSettingsTab(
            notificationEntity.notificationEntityType,
          )}/1/20/1/20`,
        ),
        search: `${searchParams}`,
      });
    },
    [history, eventStatusCode, organizationId],
  );

  const renderCell = useCallback(
    (rowData: any, cellId: string) => {
      if (cellId === 'name') {
        const cellContent = [rowData.firstName, rowData.lastName];
        return (
          <div className={classes.name}>
            <Avatar
              content={
                cellContent[0] &&
                cellContent[1] &&
                getUserInitials(cellContent[0], cellContent[1])
              }
              color="highlightBlue"
              size={32}
            />
            <div className={classes.text}>
              {cellContent[0] &&
                cellContent[1] &&
                `${cellContent[0]} ${cellContent[1]}`}
            </div>
          </div>
        );
      } else if (cellId === 'notificationEntityBaseModel') {
        return (
          <div className={classes.item}>
            <CustomTypography variant="bodySmall" color="accentDark">
              {`${t(
                translations.batchEventCard.origin,
              )}: ${getNotificationEntityName(
                rowData[cellId].notificationEntityType,
              )}`}
            </CustomTypography>

            <NotificationSettingsIcon
              stroke={themeColors.mbsBlue}
              className={classes.sentNotificationsLink}
              onClick={() => notificationSettingsClickHandler(rowData[cellId])}
            />
          </div>
        );
      } else {
        return (
          <div style={{ wordBreak: 'break-all', textAlign: 'start' }}>
            {rowData[cellId] || '-'}
          </div>
        );
      }
    },
    [classes.name, classes.text],
  );

  return (
    <div>
      <div className={classes.header}>
        <CustomTypography variant="subtitle1" color="accentDark">
          {notificationName.toUpperCase()}
        </CustomTypography>
      </div>

      <div className={classes.internalContent}>
        <Table
          columns={defaultColumns}
          tableData={notifications || []}
          withCheckbox={false}
          renderCell={renderCell}
          headerType={TableHeaderType.NO_HEADER}
          withBorders={false}
          rowId={'userId'}
          isLoading={false}
          isScrollable={false}
          overflow="visible"
          withHover={false}
          fixed
        />
      </div>
    </div>
  );
};
