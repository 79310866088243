import { Button } from 'app/shared/components/generic-ui/Button/Button';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { useStyles } from './TicketFilterBlock.styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  InputSize,
  TextInput,
} from 'app/shared/components/generic-ui/TextInput/TextInput';
import { useHistory, useParams } from 'react-router-dom';
import { useBatchHistoryList } from 'app/shared/hooks';
import { IHistoryRecord, IMetaData, Routes } from 'types';

interface ITicketFilterBlockProps {
  withStatusesOnly: boolean;
  loadingHandler: (value: boolean) => void;
  ticketsHistoryHandler: (
    items?: IHistoryRecord[],
    metadata?: IMetaData,
  ) => void;
  refresh: boolean;
  toJobRemaining: boolean;
}

export const TicketFilterBlock = ({
  withStatusesOnly,
  ticketsHistoryHandler,
  loadingHandler,
  refresh,
  toJobRemaining,
}: ITicketFilterBlockProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organizationId }: { organizationId: string } = useParams();
  const history = useHistory();
  const ticketId = new URLSearchParams(location.search).get('ticketId');

  const [currentTicketId, setCurrentTicketId] = useState(
    localStorage.getItem('ticketId') || '',
  );

  const ticketsHistoryQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      queryParameters: {
        ticketsWithStatusesOnly: `${withStatusesOnly}`,
        ticketNumber: ticketId || '',
        ToJobRemaining: toJobRemaining,
      },
    };
  }, [organizationId, withStatusesOnly, ticketId]);

  const {
    fetchTicketHistory,
    isFetchingTicketHistory,
    batchesHistoryMetadata,
    batchesHistory,
  } = useBatchHistoryList(ticketsHistoryQueryParams);

  const applyFilterHandler = useCallback(async () => {
    localStorage.setItem('batchesFrom', '');
    localStorage.setItem('batchesTo', '');
    localStorage.setItem('batchesPlants', '[]');
    localStorage.setItem('batchesCustomers', '[]');
    localStorage.setItem('batchesMixDesigns', '[]');
    localStorage.setItem('batchesVehicles', '[]');
    localStorage.setItem('batchesDrivers', '[]');
    localStorage.setItem('batchesProjects', '[]');
    localStorage.setItem('batchesPourEvents', '[]');

    localStorage.setItem('ticketId', currentTicketId);

    const searchParams = new URLSearchParams({
      ['ticketId']: currentTicketId,
    });

    history.push({
      pathname: Routes.Batches.replace(
        ':organizationId/:page/:perPage',
        `${organizationId}/1/20`,
      ),
      search: `${searchParams}`,
    });
  }, [currentTicketId, history, organizationId]);

  useEffect(() => {
    if (ticketId) {
      fetchTicketHistory();
    }
  }, [fetchTicketHistory, ticketId, toJobRemaining, refresh]);

  useEffect(() => {
    if (ticketId) {
      ticketsHistoryHandler(batchesHistory, batchesHistoryMetadata);
    }
  }, [batchesHistory, batchesHistoryMetadata, ticketId, ticketsHistoryHandler]);

  useEffect(() => {
    loadingHandler(isFetchingTicketHistory);
  }, [isFetchingTicketHistory, loadingHandler]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <CustomTypography variant="caption1" color="greyscale1">
          {t(translations.filterBlock.ticket)}
        </CustomTypography>

        <TextInput
          name="ticketId"
          onKeyPress={e => {
            if (!new RegExp('^[0-9]$').test(e.key)) {
              e.preventDefault();
            }
          }}
          borderRadius={2}
          customSize={InputSize.BIG}
          currentValue={currentTicketId}
          placeholder={`${t(translations.filterBlock.typeHere)}...`}
          onChangeHandler={e => {
            setCurrentTicketId(e?.target.value || '');
          }}
        />
      </div>

      <div className={classes.applyButton}>
        <Button
          onClick={applyFilterHandler}
          height={42}
          variant={'secondaryLight'}
        >
          <CustomTypography variant="buttonTextSmall" bold color={'mbsBlue'}>
            {t(translations.datePicker.apply)}
          </CustomTypography>
        </Button>
      </div>
    </div>
  );
};
