import { useCallback, useEffect, useState } from 'react';
import { ITeamAssignments } from 'types';

export const useTeamAssignmentsList = ({
  teamAssignments,
  isResetting,
}: {
  teamAssignments: ITeamAssignments[] | undefined;
  isResetting?: boolean;
}) => {
  const [currentTeamAssignments, setCurrentTeamAssignments] = useState<
    ITeamAssignments[] | undefined
  >([]);

  useEffect(() => {
    setCurrentTeamAssignments(teamAssignments || []);
  }, [teamAssignments, isResetting]);

  const getCheckedState: (
    notificationCode: string,
    teamAssignments?: ITeamAssignments[],
  ) => boolean = useCallback(
    (notificationCode: string, teamAssignments?: ITeamAssignments[]) => {
      let checked = true;
      teamAssignments?.map(el => {
        el.notifications?.find(
          notification => notification.notificationCode === notificationCode,
        )?.isAssigned === false
          ? (checked = false)
          : checked;
      });
      return checked;
    },
    [],
  );

  const changeSingleAssignmentHandler = useCallback(
    (
      isAssigned: boolean,
      notificationCode: string,
      teamAssignment: ITeamAssignments,
    ) => {
      let item = teamAssignments?.find(
        el => el.userId === teamAssignment.userId,
      );

      if (item) {
        item = {
          ...item,
          notifications: item?.notifications.map(notification => {
            return notification.notificationCode === notificationCode
              ? { ...notification, isAssigned: isAssigned }
              : notification;
          }),
        };

        const newTeammates = teamAssignments?.map(teamAssignment => {
          return teamAssignment.userId === item?.userId ? item : teamAssignment;
        });

        setCurrentTeamAssignments(newTeammates);
      }
    },
    [teamAssignments],
  );

  const changeMultipleAssignmentHandler = useCallback(
    (
      isAssigned: boolean,
      notificationCodes: string[],
      teamAssignments?: ITeamAssignments[],
    ) => {
      const newTeammates = teamAssignments?.map(teamAssignment => {
        return teamAssignments?.includes(teamAssignment)
          ? {
              ...teamAssignment,
              notifications: teamAssignment.notifications.map(notification => {
                return notificationCodes.includes(notification.notificationCode)
                  ? {
                      ...notification,
                      isAssigned: isAssigned,
                    }
                  : notification;
              }),
            }
          : teamAssignment;
      });
      setCurrentTeamAssignments(newTeammates);
    },
    [teamAssignments],
  );

  return {
    teamAssignments: currentTeamAssignments,
    getCheckedState,
    changeSingleAssignmentHandler,
    changeMultipleAssignmentHandler,
  };
};
