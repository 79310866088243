import React, { useCallback, useRef, useState } from 'react';
import {
  Checkbox,
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import {
  CellAlignment,
  CellSize,
  IHeadCell,
  idCellWidth,
  IRenderRowProps,
  TableHeaderType,
  PaginationType,
} from './Table.types';
import { TablePagination } from './TablePagination';
import { CustomTableHead } from './TableHead';
import { useStyles } from './Table.styles';
import { themeColors } from 'app/shared/theme';
import { CustomTypography } from '../Typography/Typography';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  ActionButtonStyle,
} from 'app/shared/components/generic-ui/ActionButton/ActionButton';
import { CircularProgress } from '../CircularProgress';
import { themeShadows } from 'app/shared/theme/theme';

const defaultPropsValues = {
  withBorders: true,
  withColoredRows: false,
  withHover: true,
  headerType: TableHeaderType.DARK,
  withPagination: false,
  isScrollable: true,
  orderBy: '',
  headRowsCount: 1,
  withPerPageCount: false,
  paginationType: PaginationType.MAIN,
  withPadding: true,
  withHeaderPadding: true,
  overflow: 'auto',
};

const uncheckedCheckbox = -1;
const checkedCheckbox = 1;
const emptyArrayLength = 0;
const minRowsForPagination = 5;
const extraColumn = 1;
const noExtraColumns = 0;
const twoExtraColumns = 2;
const headerHeight = 49;
const onBackground = 'auto';
const inFront = 1500;

interface ITableProps<T> {
  tableData?: any[];
  columns: IHeadCell[];
  withCheckbox: boolean;
  withoutHeaderCheckbox?: boolean;
  minHeight?: number;
  minWidth?: number;
  actions?: {
    onClick: (item: T, index?: number) => void;
    label: string;
  }[];
  onClickRow?: (rowData: any) => void;
  rowId?: string;
  renderCell?: (
    rowData: any,
    cellId: string,
    isHovered?: boolean,
    label?: string,
  ) => any;

  renderActions?: (rowData: any) => boolean;
  renderCellWithIndexOfRow?: (
    rowData: any,
    cellId: string,
    index: number,
    label: string,
    isHovered?: boolean,
  ) => any;
  headerType?: TableHeaderType;
  withBorders?: boolean;
  withColoredRows?: boolean;
  withHover?: boolean;
  withPagination?: boolean;
  sendSelectedItems?: (ids: string[]) => void;
  isLoading?: boolean;
  emptyTableText?: string;
  sortHandler?: (id: string) => void;
  isScrollable?: boolean;
  headRowsCount?: number;
  onChangeHeadCell?: (id: string, label: string) => void;
  withPadding?: boolean;
  withHeaderPadding?: boolean;
  headerButton?: JSX.Element;
  changeSelectedState?: (id: string[]) => void;
  overflow?: string;
  //pagination
  rowsTotal?: number;
  orderBy?: string;
  baseURL?: string;
  withPerPageCount?: boolean;
  searchParams?: URLSearchParams;
  paginationType?: PaginationType;
  selectedData?: string[];
  paginationChangeHandler?: () => Promise<boolean>;
  noHeight?: boolean;
  overflowX?: 'hidden';
  fixed?: boolean;
}

function Table<T>({
  tableData,
  columns,
  withCheckbox,
  actions,
  rowsTotal,
  sendSelectedItems,
  onClickRow,
  renderActions,
  orderBy = defaultPropsValues.orderBy,
  renderCell,
  rowId,
  renderCellWithIndexOfRow,
  withBorders = defaultPropsValues.withBorders,
  withColoredRows = defaultPropsValues.withColoredRows,
  withHover = defaultPropsValues.withHover,
  headerType = defaultPropsValues.headerType,
  withPagination = defaultPropsValues.withPagination,
  emptyTableText,
  baseURL,
  isLoading,
  sortHandler,
  isScrollable = defaultPropsValues.isScrollable,
  headRowsCount = defaultPropsValues.headRowsCount,
  onChangeHeadCell,
  headerButton,
  withPerPageCount = defaultPropsValues.withPerPageCount,
  searchParams,
  paginationType = defaultPropsValues.paginationType,
  withPadding = defaultPropsValues.withPadding,
  withHeaderPadding = defaultPropsValues.withHeaderPadding,
  minHeight,
  minWidth,
  selectedData,
  changeSelectedState,
  overflow = defaultPropsValues.overflow,
  paginationChangeHandler,
  withoutHeaderCheckbox,
  noHeight,
  overflowX,
  fixed,
}: ITableProps<T>) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedData || [],
  );
  const [hoveredRow, setHoveredRow] = useState<any | undefined>(undefined);
  const refToScroll = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const extraColumns =
    actions && withCheckbox
      ? twoExtraColumns
      : !actions && !withCheckbox
      ? noExtraColumns
      : extraColumn;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedItems: string[] | undefined = tableData?.map(
        (item: any) => (rowId ? item[rowId] : item.id),
      );
      changeSelectedState &&
        newSelectedItems &&
        changeSelectedState(newSelectedItems);
      newSelectedItems && setSelectedItems(newSelectedItems);
      sendSelectedItems && sendSelectedItems(newSelectedItems || []);
      return;
    }
    sendSelectedItems && sendSelectedItems([]);
    setSelectedItems([]);
  };

  const handleClick = useCallback(
    (id: string) => {
      changeSelectedState && changeSelectedState([id]);
      const selectedItem = selectedItems.indexOf(id);
      let newSelected: Array<string> = [];

      if (selectedItem === uncheckedCheckbox) {
        newSelected = newSelected.concat(selectedItems, id);
      } else if (selectedItem === emptyArrayLength) {
        newSelected = newSelected.concat(selectedItems.slice(checkedCheckbox));
      } else if (selectedItem === selectedItems.length - checkedCheckbox) {
        newSelected = newSelected.concat(
          selectedItems.slice(emptyArrayLength, uncheckedCheckbox),
        );
      } else if (selectedItem > emptyArrayLength) {
        newSelected = newSelected.concat(
          selectedItems.slice(emptyArrayLength, selectedItem),
          selectedItems.slice(selectedItem + checkedCheckbox),
        );
      }

      sendSelectedItems && sendSelectedItems(newSelected);
      return newSelected;
    },

    [changeSelectedState, selectedItems, sendSelectedItems],
  );

  const isSelected = useCallback(
    (id: string) => {
      return selectedItems.indexOf(id) !== uncheckedCheckbox;
    },
    [selectedItems],
  );

  const renderRow = useCallback(
    ({ columns, rowData, index }: IRenderRowProps) => {
      return columns.map(
        ({ id, size, alignment, rowNumber, label, key, sticky }: IHeadCell) => {
          return !rowNumber || (rowNumber && rowNumber === headRowsCount) ? (
            <TableCell
              key={`${index}-${key || id}`}
              onClick={() => {
                if (onClickRow && id !== 'actions') {
                  onClickRow(rowData);
                  rowId && setSelectedItems(handleClick(rowData[rowId]));
                }
              }}
              classes={{
                root:
                  !withCheckbox && headerType !== TableHeaderType.LIGHT
                    ? withPadding
                      ? classes.cellWithoutCheckbox
                      : classes.cellWithoutPadding
                    : !withPadding
                    ? classes.withoutPadding
                    : classes.cell,
              }}
              align={alignment}
              style={{
                width: fixed
                  ? `${100 / columns.length}%`
                  : size === CellSize.SMALL
                  ? idCellWidth
                  : 'inherit',
                color: themeColors.accentDark,
                borderBottom: withBorders ? '' : 'none',
                position: sticky ? 'sticky' : 'relative',
                left: 0,
                boxShadow: sticky ? themeShadows.shadowMedium : '',
                backgroundColor:
                  hoveredRow === rowData ||
                  (rowId && isSelected(rowData[rowId]))
                    ? themeColors.highlightBlue
                    : themeColors.white,
                zIndex: sticky ? inFront : onBackground,
              }}
            >
              {renderCell &&
                renderCell(rowData, id, hoveredRow === rowData, label)}

              {renderCellWithIndexOfRow &&
                renderCellWithIndexOfRow(
                  rowData,
                  id,
                  index,
                  label,
                  hoveredRow === rowData,
                )}

              {!renderCell && !renderCellWithIndexOfRow && (
                <div>{rowData[id]}</div>
              )}
            </TableCell>
          ) : null;
        },
      );
    },
    [
      classes.cell,
      classes.cellWithoutCheckbox,
      classes.cellWithoutPadding,
      classes.withoutPadding,
      headRowsCount,
      headerType,
      hoveredRow,
      isSelected,
      onClickRow,
      renderCell,
      renderCellWithIndexOfRow,
      rowId,
      withBorders,
      withCheckbox,
      withPadding,
      handleClick,
    ],
  );

  const renderRows = useCallback(
    rowsData => {
      return rowsData.map((rowData: any, index: number) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const renderCurrentRow = renderRow({ columns, rowData, index });

        return (
          <TableRow
            classes={{
              selected: classes.row,
              root:
                withColoredRows && hoveredRow !== rowData
                  ? classes.rowBackground
                  : hoveredRow === rowData
                  ? classes.hover
                  : '',
            }}
            onMouseEnter={() => {
              withHover && setHoveredRow(rowData);
            }}
            onMouseLeave={() => {
              withHover && setHoveredRow(undefined);
            }}
            role="checkbox"
            aria-checked={!!rowId && isSelected(rowData[rowId])}
            tabIndex={-1}
            key={index}
            selected={!!rowId && isSelected(rowData[rowId])}
            style={{
              cursor: onClickRow ? 'pointer' : '',
            }}
          >
            {withCheckbox && (
              <TableCell
                classes={{ root: classes.cellVariantWithCheckbox }}
                style={{
                  borderBottom: withBorders ? '' : 'none',
                  backgroundColor:
                    hoveredRow === rowData ||
                    (rowId && isSelected(rowData[rowId]))
                      ? themeColors.highlightBlue
                      : themeColors.white,
                }}
              >
                <Checkbox
                  checked={rowId ? isSelected(rowData[rowId]) : false}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  classes={{
                    root: classes.checkbox,
                    colorSecondary: classes.icon,
                    checked: classes.checkedIcon,
                  }}
                  onClick={() => {
                    rowId && setSelectedItems(handleClick(rowData[rowId]));
                  }}
                />
              </TableCell>
            )}

            {renderCurrentRow}

            {actions && (
              <TableCell
                padding="none"
                align="right"
                style={{
                  width: '5%',
                  paddingRight: '11px',
                  borderBottom: withBorders ? '' : 'none',
                  backgroundColor:
                    hoveredRow === rowData
                      ? themeColors.highlightBlue
                      : themeColors.white,
                }}
              >
                {((renderActions && renderActions(rowData)) ||
                  !renderActions) && (
                  <ActionButton
                    style={ActionButtonStyle.LIGHT}
                    options={actions}
                    rowData={rowData}
                    rowIndex={index}
                  />
                )}
              </TableCell>
            )}
          </TableRow>
        );
      });
    },
    [
      actions,
      classes.cellVariantWithCheckbox,
      classes.checkbox,
      classes.checkedIcon,
      classes.hover,
      classes.icon,
      classes.row,
      classes.rowBackground,
      columns,
      handleClick,
      hoveredRow,
      isSelected,
      onClickRow,
      renderActions,
      renderRow,
      rowId,
      withBorders,
      withCheckbox,
      withColoredRows,
      withHover,
    ],
  );

  const scrollToComponent = useCallback(() => {
    return refToScroll?.current &&
      tableRef?.current &&
      refToScroll?.current?.offsetTop < tableRef?.current?.offsetHeight
      ? refToScroll?.current?.scrollIntoView()
      : null;
  }, [refToScroll]);

  return (
    <div
      ref={tableRef}
      style={{
        minWidth:
          !isScrollable && minWidth ? minWidth : isScrollable ? '100%' : '',
        height: isScrollable ? '100%' : '',
        backgroundColor: themeColors.greyscale4,
      }}
    >
      <div ref={refToScroll} />
      <MuiPaper classes={{ root: classes.paper }} ref={tableRef}>
        <TableContainer
          classes={{ root: classes.root }}
          style={{
            overflow: overflow,
            overflowX: overflowX,
            minHeight: minHeight ? minHeight : '',
          }}
        >
          <MuiTable
            style={{
              borderBottom: 'none',
              tableLayout: fixed ? 'fixed' : undefined,
            }}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <CustomTableHead
              numSelected={selectedItems.length}
              orderBy={orderBy}
              rowCount={tableData?.length || emptyArrayLength}
              onSelectAllClick={handleSelectAllClick}
              withCheckbox={withCheckbox}
              withoutHeaderCheckbox={withoutHeaderCheckbox}
              headerType={headerType}
              sortHandler={sortHandler}
              onChangeHeadCell={onChangeHeadCell}
              headerButton={headerButton}
              withPadding={withHeaderPadding}
              noHeight={noHeight}
              headCells={columns.map(
                ({
                  id,
                  label,
                  size,
                  sortable,
                  sortBy,
                  alignment,
                  colSpan,
                  rowNumber,
                  dropDownValues,
                  buttons,
                  maxWidth,
                  borderLeft,
                  borderRight,
                  sticky,
                }: IHeadCell) => ({
                  id,
                  alignment: alignment ? alignment : CellAlignment.LEFT,
                  label,
                  size,
                  sortable,
                  sortBy,
                  colSpan,
                  rowNumber,
                  dropDownValues,
                  buttons,
                  maxWidth: maxWidth || false,
                  borderLeft: borderLeft || false,
                  borderRight: borderRight || false,
                  sticky,
                }),
              )}
              hasActions={!!actions}
            />

            {tableData && tableData.length > emptyArrayLength && !isLoading ? (
              <TableBody>{renderRows(tableData)}</TableBody>
            ) : (
              <TableBody>
                <TableRow
                  style={{
                    height: minHeight ? minHeight - headerHeight : 'fitContent',
                  }}
                >
                  <TableCell
                    colSpan={columns.length + extraColumns}
                    align="center"
                    className={
                      withBorders
                        ? classes.emptyTableCell
                        : classes.emptyTableCellNoBorder
                    }
                  >
                    {isLoading ? (
                      <CircularProgress withLabel />
                    ) : (
                      <CustomTypography
                        variant="bodyRegular"
                        color="greyscale3"
                      >
                        {emptyTableText
                          ? emptyTableText
                          : t(
                              translations.teammatesPage
                                .thereAreCurrentlyNoTeammates,
                            )}
                      </CustomTypography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </MuiTable>
        </TableContainer>

        {!!rowsTotal && rowsTotal >= minRowsForPagination && withPagination && (
          <TablePagination
            count={rowsTotal}
            baseURL={baseURL}
            withPerPageCount={withPerPageCount}
            searchParams={searchParams}
            paginationType={paginationType}
            paginationChangeHandler={paginationChangeHandler}
            tableScrollHandler={scrollToComponent}
          />
        )}
      </MuiPaper>
    </div>
  );
}

export { Table };
