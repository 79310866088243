import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebaseAnalytics } from 'app/shared/analytics';
import {
  IDeleteUserNotificationAssignmentsPayload,
  IGetUserNotificationAssignmentsPayload,
  INotification,
  IProfile,
  ITeamAssignments,
  NotificationEntityType,
  Routes,
} from 'types';
import { useNotificationSettingsGroupApi } from 'app/shared/hooks';
import { NotificationSettings } from 'app/shared/components/generic-ui/NotificationSettings';
import { SectionSpoiler } from 'app/shared/components/generic-ui/SectionSpoiler';
import { translations } from 'app/locales/i18n';
import { useTranslation } from 'react-i18next';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { PaginationType } from 'app/shared/components/generic-ui/Table/Table.types';
import { useSearchParams } from '../hooks';
import { useNotificationSettings } from 'app/shared/providers';
import { useUserNotificationAssignmentApi } from 'app/shared/hooks/useUserNotificationAssignmentApi';
import { UserAssignmentModal } from 'app/shared/components/generic-ui/UserAssignmentModal/UserAssignmentModal';
export interface IUsersAssigned {
  [key: string]: IProfile[];
}

const skipOnePage = 1;

interface IGroupAssignmentsProps {
  isUnAssigningUser: boolean;
}

export const GroupAssignments = ({
  isUnAssigningUser,
}: IGroupAssignmentsProps) => {
  const { t } = useTranslation();
  const {
    organizationId,
    page,
    perPage,
    tab,
  }: {
    organizationId: string;
    page: string;
    perPage: string;
    alternativePage: string;
    alternativePerPage: string;
    tab: string;
  } = useParams();

  const { sortHandler, searchParams, sortType, searchQuery } =
    useSearchParams();

  const { selectedEntity, entityType } = useNotificationSettings();

  const [search, setSearch] = useState('');
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [notification, setNotification] = useState<INotification>();
  const [teamAssignment, setTeamAssignment] = useState<ITeamAssignments>();

  const queryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId,
        skip: (+page - skipOnePage) * +perPage,
        take: perPage,
        query: searchQuery || '',
        sortBy: 'firstname,lastname',
        sortAsc: sortType === 'desc' ? false : true,
      },
    };
  }, [
    selectedEntity?.notificationEntityGroupId,
    entityType,
    organizationId,
    page,
    perPage,
    searchQuery,
    sortType,
  ]);

  const searchQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId,
        query: search,
        skip: 0,
        take: 10,
        sortBy: 'firstname,lastname',
        sortAsc: true,
      },
    };
  }, [
    organizationId,
    entityType,
    selectedEntity?.notificationEntityGroupId,
    search,
  ]);

  const {
    fetchNotificationSettingsGroupAssignments,
    isFetchingNotificationSettingsGroupAssignments,
    notificationSettingsAssignments,
    notificationSettingsAssignmentsMetadata,
  } = useNotificationSettingsGroupApi(queryParams);

  const {
    searchTeamAssignmentsGroup,
    fetchSearchTeamAssignmentsGroup,
    isFetchingSearchTeamAssignmentsGroup,
  } = useNotificationSettingsGroupApi(searchQueryParams);

  useEffect(() => {
    if (selectedEntity?.notificationEntityGroupId && !isUnAssigningUser) {
      fetchNotificationSettingsGroupAssignments();
    }
  }, [
    fetchNotificationSettingsGroupAssignments,
    selectedEntity,
    queryParams,
    isUnAssigningUser,
  ]);

  useEffect(() => {
    if (selectedEntity?.notificationEntityGroupId && search) {
      fetchSearchTeamAssignmentsGroup();
    }
  }, [
    fetchSearchTeamAssignmentsGroup,
    selectedEntity,
    search,
    searchQueryParams,
  ]);

  const notificationsQueryParams = useMemo(() => {
    return {
      organizationId: organizationId,
      entityType: entityType,
      queryParameters: {
        groupId: selectedEntity?.notificationEntityGroupId,
      },
    };
  }, [selectedEntity?.notificationEntityGroupId, entityType, organizationId]);

  const {
    fetchNotificationSettingsEntityGroup: fetchNotifications,
    isFetchingNotificationSettingsEntityGroup: isFetchingNotifications,
    notificationSettingsEntityGroup: notifications,
    changeNotificationStatusEntityGroup,
    changeNotificationPropertyEntityGroup,
    changeUsersStatusNotificationGroup,
    changeNotificationStatusAllGroup,
    enableNotificationEntityGroup,
    enableNotificationAllGroup,
  } = useNotificationSettingsGroupApi(notificationsQueryParams);

  const {
    deleteUserNotificationAssignments,
    getUserNotificationAssignments,
    getUserNotificationAssignmentsResponse,
    isLoadingUserNotificationAssignments,
  } = useUserNotificationAssignmentApi({
    organizationId,
    notificationEntityId:
      entityType === NotificationEntityType.ORGANIZATION
        ? organizationId
        : selectedEntity?.internalId || '',
  });

  const getUserNotificationAssignmentsCall = useCallback(
    async (
      params: IGetUserNotificationAssignmentsPayload,
      notification: INotification | undefined,
      teamAssignment: ITeamAssignments,
    ) => {
      getUserNotificationAssignments(params);
      setUserModalOpen(true);
      setNotification(notification);
      setTeamAssignment(teamAssignment);
    },
    [getUserNotificationAssignments],
  );

  useEffect(() => {
    if (selectedEntity?.notificationEntityGroupId) {
      fetchNotifications();
    }
  }, [fetchNotifications, selectedEntity?.notificationEntityGroupId]);

  const entityName = useMemo(() => {
    return (
      tab
        .replace('-', ' ')
        .split(' ')
        // eslint-disable-next-line no-magic-numbers
        .map(el => el[0].toUpperCase() + el.substring(1))
        .join(' ')
    );
  }, [tab]);

  const removeAllOtherUserNotificationAssignments = useCallback(
    async (params: IDeleteUserNotificationAssignmentsPayload) => {
      await deleteUserNotificationAssignments(params);
      if (
        selectedEntity?.internalId ||
        entityType === NotificationEntityType.ORGANIZATION
      ) {
        if (!isUnAssigningUser) {
          fetchNotificationSettingsGroupAssignments();
        }
        fetchNotifications();
      }
    },
    [
      deleteUserNotificationAssignments,
      entityType,
      fetchNotificationSettingsGroupAssignments,
      fetchNotifications,
      isUnAssigningUser,
      selectedEntity?.internalId,
    ],
  );

  const changeNotificationStatusHandler = useCallback(
    async (notification: { notificationTypeId?: string; active: boolean }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `${entityName} group notification card switch`,
      );

      await changeNotificationStatusEntityGroup(notification);
      fetchNotifications();
    },
    [changeNotificationStatusEntityGroup, entityName, fetchNotifications],
  );

  const changeNotificationsStatusAllHandler = useCallback(
    async (group: { active: boolean; description?: string }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        group.active
          ? `Activate all ${entityName} group notification statuses`
          : `Deactivate all ${entityName} group notification statuses`,
      );

      await changeNotificationStatusAllGroup({
        active: group.active,
        description: group.description,
      });

      fetchNotifications();
    },
    [changeNotificationStatusAllGroup, entityName, fetchNotifications],
  );

  const changeUsersNotificationStatusHandler = useCallback(
    async (
      users: {
        userId: string;
        notificationTypeId: string;
        isAssigned: boolean;
      }[],
    ) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Change ${entityName} group user notification status`,
      );

      await changeUsersStatusNotificationGroup({ users });
      fetchNotificationSettingsGroupAssignments();
    },
    [
      changeUsersStatusNotificationGroup,
      entityName,
      fetchNotificationSettingsGroupAssignments,
    ],
  );

  const changeNotificationPropertyHandler = useCallback(
    async notification => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `Change ${entityName} group notification property`,
      );

      await changeNotificationPropertyEntityGroup(notification);

      fetchNotifications();
    },
    [changeNotificationPropertyEntityGroup, entityName, fetchNotifications],
  );

  const enableNotificationHandler = useCallback(
    async (notification: { notificationTypeId?: string; enable: boolean }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        `${entityName} notification enable card switch`,
      );

      await enableNotificationEntityGroup(notification);

      fetchNotifications();
    },
    [enableNotificationEntityGroup, entityName, fetchNotifications],
  );

  const enableNotificationsAllHandler = useCallback(
    async (group: { enable: boolean; description?: string }) => {
      firebaseAnalytics.logPressEvent(
        Routes.NotificationSettings,
        group.enable
          ? `Enable all ${entityName} level notifications`
          : `Disable all ${entityName} level notifications`,
      );

      await enableNotificationAllGroup({
        enable: group.enable,
        description: group.description,
      });

      fetchNotifications();
    },
    [enableNotificationAllGroup, entityName, fetchNotifications],
  );

  return (
    <div>
      <SectionSpoiler
        header={t(translations.notificationSettingsPage.notificationsAndTeam, {
          entity: entityName,
        })}
        withIcons={true}
      >
        {selectedEntity?.notificationEntityGroupId ? (
          <NotificationSettings
            isFetching={isFetchingNotifications}
            notifications={notifications}
            teammatesList={notificationSettingsAssignments}
            teammatesListTotalCount={
              notificationSettingsAssignmentsMetadata?.totalCount
            }
            isFetchingTeammatesList={
              isFetchingNotificationSettingsGroupAssignments &&
              !notificationSettingsAssignments
            }
            removeAllOtherUserNotificationAssignments={
              removeAllOtherUserNotificationAssignments
            }
            getUserNotificationAssignmentsCall={
              getUserNotificationAssignmentsCall
            }
            searchList={searchTeamAssignmentsGroup}
            isFetchingSearch={isFetchingSearchTeamAssignmentsGroup}
            changeNotificationsStatusAll={changeNotificationsStatusAllHandler}
            changeNotificationStatus={changeNotificationStatusHandler}
            changeUsersNotificationStatus={changeUsersNotificationStatusHandler}
            changeNotificationProperty={changeNotificationPropertyHandler}
            enableNotification={enableNotificationHandler}
            enableNotificationsAll={enableNotificationsAllHandler}
            searchTermHandler={setSearch}
            sortHandler={sortHandler}
            baseUrl={Routes.NotificationSettings.replace(
              ':organizationId/:tab',
              `${organizationId}/${tab}`,
            )}
            searchParams={searchParams()}
            paginationType={PaginationType.MAIN}
          />
        ) : (
          <CustomTypography variant="subtitle1" color={'greyscale2'}>
            {t(translations.notificationSettings.noNotificationSettings)}
          </CustomTypography>
        )}
      </SectionSpoiler>
      <UserAssignmentModal
        isOpen={userModalOpen}
        closeHandler={() => setUserModalOpen(false)}
        notification={notification}
        teamAssignment={teamAssignment}
        isLoading={isLoadingUserNotificationAssignments}
        tableData={getUserNotificationAssignmentsResponse?.data}
        changeUsersNotificationStatusHandler={
          changeUsersNotificationStatusHandler
        }
      />
    </div>
  );
};
