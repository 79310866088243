import { themeColors } from 'app/shared/theme/theme';
import React from 'react';
import { CustomTypography } from '../Typography/Typography';
import { useStyles } from './Avatar.styles';

interface IAvatar {
  size: number;
  content?: string;
  color?: keyof typeof themeColors;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const sizeForProfileAvatar = 150;

export const Avatar = ({ size, content, color, onClick }: IAvatar) => {
  const classes = useStyles();

  return (
    <div
      style={{
        height: size,
        width: size,
        backgroundColor: color ? themeColors[color] : themeColors.highlightBlue,
      }}
      className={classes.avatarContainer}
      onClick={onClick}
    >
      <CustomTypography
        variant={
          size === sizeForProfileAvatar ? 'headerExtraLarge' : 'bodySmall'
        }
        bold
        color={color === 'greyscale4' ? 'greyscale2' : 'mbsBlue'}
        style={{ minWidth: size, textAlign: 'center', fontWeight: 'bold' }}
      >
        {content?.toUpperCase() ?? ''}
      </CustomTypography>
    </div>
  );
};
