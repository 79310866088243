import React, { useCallback } from 'react';
import { CustomTypography } from 'app/shared/components/generic-ui/Typography/Typography';
import { useStyles } from './NotificationsTabs.styles';
import { useHistory, useLocation } from 'react-router';

interface INotificationsTabsProps {
  categories: string[];
  activeTab: string;
}

export const NotificationsTabs = ({
  categories,
  activeTab,
}: INotificationsTabsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const changeTabHandler = useCallback(
    (tab: string) => {
      const searchParams = new URLSearchParams({
        ['tab']: tab,
      });

      history.push({
        pathname: location.pathname,
        search: `${searchParams}`,
      });
    },
    [history, location.pathname],
  );

  return (
    <div className={classes.wrapper}>
      {categories.map(category => {
        return (
          <div
            className={activeTab === category ? classes.activeTab : classes.tab}
            key={category}
            onClick={() => changeTabHandler(category)}
          >
            <CustomTypography
              variant="buttonTabText"
              bold={activeTab === category}
              color={activeTab === category ? 'mbsBlue' : 'accentDark'}
            >
              {category}
            </CustomTypography>
          </div>
        );
      })}
    </div>
  );
};
