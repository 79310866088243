export const idCellWidth = '5%';

export enum CellType {
  ARRAY = 'array',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
}

export enum CellAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  INHERIT = 'inherit',
  JUSTIFY = 'justify',
}
export enum CellSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export interface IRowType {
  [key: string]: string | number;
  id: string;
  product: string;
  date: string;
  total: string;
  status: number;
  method: string;
}

export interface IHeadCell {
  key?: number;
  id: string;
  size: CellSize;
  alignment?: CellAlignment;
  label: string;
  disablePadding?: boolean;
  sortable?: boolean;
  sortBy?: string;
  callback?: (row: any, refresh: () => void) => void;
  cellType?: CellType;
  colSpan?: number;
  rowSpan?: number;
  rowNumber?: number;
  dropDownValues?: any[];
  borderLeft?: boolean;
  borderRight?: boolean;
  maxWidth?: boolean;
  sticky?: boolean;
  buttons?: {
    button: JSX.Element;
    onClick?: () => void;
  }[];
}

export interface ITableHeadProps {
  numSelected: number;
  orderBy: string;
  rowCount: number;
  headCells: Array<IHeadCell>;
  withCheckbox: boolean;
  withoutHeaderCheckbox?: boolean;
  hasActions: boolean;
  headerType?: TableHeaderType;
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sortHandler?: (id: string) => void;
  onChangeHeadCell?: (id: string, label: string) => void;
  headerButton?: JSX.Element;
  withPadding?: boolean;
  noHeight?: boolean;
}

export interface IPaginationUrlParams {
  page: string;
  perPage: string;
  alternativePage: string;
  alternativePerPage: string;
  orderType: 'asc' | 'desc';
}
export interface IRenderRowProps {
  columns: IHeadCell[];
  rowData: any;
  index: number;
}

export enum TableHeaderType {
  DARK = 'dark',
  LIGHT = 'light',
  NO_HEADER = 'noHeader',
  ACTIVE = 'active',
}

export enum PaginationType {
  MAIN = 'main',
  SECOND_LEVEL = 'second-level',
}
